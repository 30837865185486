.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;

    margin-top: 10px;
}

.chartContainer {
    width: 100%;
    margin-top: 10px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 15px;
}

.sendFormButton {
    margin-top: 5px;
    width: 45%
}
