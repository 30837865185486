.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: auto;

    width: 900px;
}

.authForm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-top: 30px;
}

.loginButton {
    margin-top: 10px;
    width: 200px;
    /*margin-left: auto;*/
}

.accountInfoInput {
    font-size: 20px;
    width: 300px;
    border-color: #111111;
    border-width: 3px;
    align-self: center;
    text-align: center;
    margin-top: 5px;
}
