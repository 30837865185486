@import url(//fonts.googleapis.com/css?family=Play);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;

  /*margin: 0 50px 0 50px;*/
  font-family: 'Play', sans-serif !important;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Button_button__3QF_k {
  cursor: pointer;
  outline: none;
  /*box-sizing: border-box;*/
  position: relative;
  color: white;
  /*border: 1px solid black;*/
  background-color: black;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;

  font-weight: bold;
}

.Button_clickable__2pDCz {
  transition: all .2s ease-in-out;
}

.Button_clickable__2pDCz:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.Button_large__3F305 {
  width: 285px;
  font-size: 25px;
}

.Button_small__1zbeU {
  width: 50px;
}

.TargetsPage_targetPage__3DUOh {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 900px;
    margin: auto;
}

.CommonHeading_backWithLogo__3uJ-U {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;

    width: 100%;
}

.CommonHeading_icon__25fQP {
    height: 90px;
}

.CommonHeading_clickable__13j1Y {
    transition: all .2s ease-in-out;
}

.CommonHeading_clickable__13j1Y:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.CommonHeading_flexCenter__Ni7_i {
    justify-self: center;
}

.MainLogo_mainLogo__18mX_ {
    /*height: 90px;*/
}

.Description_description__2LeLg {
    display:inline-block;

    margin: 20px 60px 0 60px;
    padding: 0 30px 0 30px;

    font-weight: bold;
}

.Description_info__Udig8 {
    font-size: 27px;

    background-color: black;
    color: white;
}

.Description_warning__2NPNt {
    font-size: 20px;
    background-color: whitesmoke;
    color: red;
}

.Description_smallInfo__39DZu {
    font-size: 20px;

    background-color: black;
    color: white;
}

.TargetPreview_smallLogos__1HPNQ {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 30px;
    width: 90%;
    background-color: black;
}

.TargetPreview_nicknameBlock__2lU3m {
    overflow: hidden;

    color: white;
    background-color: black;

    width: 90%;
    height: auto;
    /*padding: 0 10px 0 10px;*/

    text-align: center;
    font-size: 35px;
}

.TargetPreview_targetPreview__ddu3i {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: black;
    width: 300px;
    height: 90px;

    border-radius: 10px;
}

.TargetPreview_icon__GeBtV {
    /*width: 30px;*/
    height: 20px;
    margin: 5px;
}

.TargetPreview_controlIcon__3cXLi {
    height: 60px;
    margin-left: 10px;
}

.TargetPreview_clickable__2I8bG {
    transition: all .2s ease-in-out;
}

.TargetPreview_clickable__2I8bG:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.TargetPreview_targetPreviewWithEdit__1V2am {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 20px;
}


.TargetsContainer_targetsContainerWithAdd__1X7Pa {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 30px;

    width: 80%;
}

.TargetsContainer_targetsContainer__1FtIX {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /*margin-top: 30px;*/

    /*width: 90%;*/
}

.TargetsContainer_addIcon__bO8dj {
    height: 150px;
    align-self: flex-start;
}

.TargetsContainer_clickable__fmPKH {
    transition: all .2s ease-in-out;
}

.TargetsContainer_clickable__fmPKH:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.Loader_loader__1j2Bs {
    margin-top: 20px;
    margin-bottom: 20px;

    /*margin-left: auto;*/
}

.Loader_small__2r5Pe {
    padding-right: 30px;
    padding-bottom: 30px;
}

.Loader_big__HOkvG {
    padding-right: 60px;
    padding-bottom: 60px;
}

.Refresher_refresher__1-mMA {
    margin-top: 20px;
    margin-bottom: 20px;

    /*margin-left: auto;*/
    height: 40px;
}

.Refresher_refresher__1-mMA:hover {
    animation: Refresher_rotation__13WAl infinite 1.7s ease-in-out;
    cursor: pointer;
}

@keyframes Refresher_rotation__13WAl {
    0% {
        transform: rotate(0deg);
    }
    75%, 100% {
        transform: rotate(359deg);
    }
}


.LandingPage_page__1Fyyv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-top: 50px;
}

.LandingPage_startButton__1pLAM {
    margin-top: 10px;

    /*margin-left: auto;*/
}

.AccountsInfoForm_accountsInfoForm__1iCDY {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    width: 900px;
}

.AccountsInfoForm_icon__3evMR {
    width: 60px;
    /*height: 60px;*/
    margin: 10px;
}

.AccountsInfoForm_delete-icon__2Gypu {
    /*width: 40px;*/
    /*height: 40px;*/
}

.AccountsInfoForm_clickable__23eRt {
    transition: all .2s ease-in-out;
}

.AccountsInfoForm_clickable__23eRt:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.AccountsInfoForm_iconsContainer__1Sk9c {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.AccountsInfoForm_mainNickNameInput__1XGMn {
    outline: 0;
    font-size: 25px;
    margin-top: 10px;
    width: 300px;
    border-color: black;
    border-width: 6px;
    align-self: center;

    font-weight: bold;
    text-align: center;
}

.AccountsInfoForm_accountsInfoContainer__2MMfs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin-right: 15px;
}

.AccountsInfoForm_accountsInfoWithButton__BiavJ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    width: 80%;
}

.AccountsInfoForm_singleAccountInfoContainer__3PdWz {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.AccountsInfoForm_accountInfoInput__2fA46 {
    font-size: 20px;
    width: 250px;
    border-color: #111111;
    border-width: 3px;
    align-self: center;
    text-align: center;
}

.TargetPlotsPage_targetPlotsPage__1ElU8 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 900px;
    margin: auto;
}

.TargetPlots_icon__2PmHT {
    height: 40px;
    margin: auto;
}

.TargetPlots_iconContainer__2XiEq {
    /*height: 100%;*/
    /*width: 80px;*/
    padding: 30px;
    margin: 5px;

    border-radius: 10px;
}

.TargetPlots_blackBackground__3qGRD {
    background-color: black;
}

.TargetPlots_whiteBackground__3jgHh {
    background-color: white;
}

.TargetPlots_whiteBackground__3jgHh:hover {
    background-color: whitesmoke;
    cursor: pointer;
}

.TargetPlots_accountTypesContainer__3Tw-6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;

    margin: 10px;
}

.ResponsiveChart_mainContainer__2-9xO {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;

    margin-top: 10px;
}

.ResponsiveChart_chartContainer__3VJ3c {
    width: 100%;
    margin-top: 10px;
}

.ResponsiveChart_formContainer__J_xbq {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 15px;
}

.ResponsiveChart_sendFormButton__1D5gh {
    margin-top: 5px;
    width: 45%
}

.DateTimeRangePicker_dateTimePicker__1z_QP {
    margin-top: 15px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    /*height: auto !important;*/
    /*width: 450px !important;*/
    border-width: 5px !important;
    border-color: black !important;
    /*text-align: center !important;*/
}

.AuthPage_page__2uJHP {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: auto;

    width: 900px;
}

.AuthPage_authForm__2SsDY {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-top: 30px;
}

.AuthPage_loginButton__3opE_ {
    margin-top: 10px;
    width: 200px;
    /*margin-left: auto;*/
}

.AuthPage_accountInfoInput__HyHqk {
    font-size: 20px;
    width: 300px;
    border-color: #111111;
    border-width: 3px;
    align-self: center;
    text-align: center;
    margin-top: 5px;
}

