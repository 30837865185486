.dateTimePicker {
    margin-top: 15px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    /*height: auto !important;*/
    /*width: 450px !important;*/
    border-width: 5px !important;
    border-color: black !important;
    /*text-align: center !important;*/
}
