.accountsInfoForm {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    width: 900px;
}

.icon {
    width: 60px;
    /*height: 60px;*/
    margin: 10px;
}

.delete-icon {
    /*width: 40px;*/
    /*height: 40px;*/
}

.clickable {
    transition: all .2s ease-in-out;
}

.clickable:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.iconsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.mainNickNameInput {
    outline: 0;
    font-size: 25px;
    margin-top: 10px;
    width: 300px;
    border-color: black;
    border-width: 6px;
    align-self: center;

    font-weight: bold;
    text-align: center;
}

.accountsInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin-right: 15px;
}

.accountsInfoWithButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    width: 80%;
}

.singleAccountInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.accountInfoInput {
    font-size: 20px;
    width: 250px;
    border-color: #111111;
    border-width: 3px;
    align-self: center;
    text-align: center;
}
