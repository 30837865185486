.loader {
    margin-top: 20px;
    margin-bottom: 20px;

    /*margin-left: auto;*/
}

.small {
    padding-right: 30px;
    padding-bottom: 30px;
}

.big {
    padding-right: 60px;
    padding-bottom: 60px;
}
