.smallLogos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 30px;
    width: 90%;
    background-color: black;
}

.nicknameBlock {
    overflow: hidden;

    color: white;
    background-color: black;

    width: 90%;
    height: auto;
    /*padding: 0 10px 0 10px;*/

    text-align: center;
    font-size: 35px;
}

.targetPreview {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: black;
    width: 300px;
    height: 90px;

    border-radius: 10px;
}

.icon {
    /*width: 30px;*/
    height: 20px;
    margin: 5px;
}

.controlIcon {
    height: 60px;
    margin-left: 10px;
}

.clickable {
    transition: all .2s ease-in-out;
}

.clickable:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.targetPreviewWithEdit {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 20px;
}

