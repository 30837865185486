.button {
  cursor: pointer;
  outline: none;
  /*box-sizing: border-box;*/
  position: relative;
  color: white;
  /*border: 1px solid black;*/
  background-color: black;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;

  font-weight: bold;
}

.clickable {
  transition: all .2s ease-in-out;
}

.clickable:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.large {
  width: 285px;
  font-size: 25px;
}

.small {
  width: 50px;
}
