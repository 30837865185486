.refresher {
    margin-top: 20px;
    margin-bottom: 20px;

    /*margin-left: auto;*/
    height: 40px;
}

.refresher:hover {
    animation: rotation infinite 1.7s ease-in-out;
    cursor: pointer;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    75%, 100% {
        transform: rotate(359deg);
    }
}

