.icon {
    height: 40px;
    margin: auto;
}

.iconContainer {
    /*height: 100%;*/
    /*width: 80px;*/
    padding: 30px;
    margin: 5px;

    border-radius: 10px;
}

.blackBackground {
    background-color: black;
}

.whiteBackground {
    background-color: white;
}

.whiteBackground:hover {
    background-color: whitesmoke;
    cursor: pointer;
}

.accountTypesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;

    margin: 10px;
}
