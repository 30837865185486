.description {
    display:inline-block;

    margin: 20px 60px 0 60px;
    padding: 0 30px 0 30px;

    font-weight: bold;
}

.info {
    font-size: 27px;

    background-color: black;
    color: white;
}

.warning {
    font-size: 20px;
    background-color: whitesmoke;
    color: red;
}

.smallInfo {
    font-size: 20px;

    background-color: black;
    color: white;
}
