.backWithLogo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;

    width: 100%;
}

.icon {
    height: 90px;
}

.clickable {
    transition: all .2s ease-in-out;
}

.clickable:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.flexCenter {
    justify-self: center;
}
