.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-top: 50px;
}

.startButton {
    margin-top: 10px;

    /*margin-left: auto;*/
}
