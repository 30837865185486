.targetsContainerWithAdd {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 30px;

    width: 80%;
}

.targetsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /*margin-top: 30px;*/

    /*width: 90%;*/
}

.addIcon {
    height: 150px;
    align-self: flex-start;
}

.clickable {
    transition: all .2s ease-in-out;
}

.clickable:hover {
    cursor: pointer;
    transform: scale(1.1);
}
